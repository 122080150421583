// App
@import "app";

.text-deg1,
.text-deg2,
.text-deg3 {
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}

.text-deg1 {
    background-color: $light;
    background-image: linear-gradient(45deg, $light, $tertiary);
}

.text-deg2 {
    background-color: $primary;
    background-image: linear-gradient(45deg, $primary, $tertiary);
}

.text-deg3 {
    background-color: $quaternary;
    background-image: linear-gradient(45deg, $quaternary, $tertiary);
}

.bg-dark-deg {
    background-image: linear-gradient(0deg, $dark, #000000);
}

.bg-blue-deg {
    background-image: linear-gradient(0deg, $primary, $secondary);
}

.alert-card {
    position: fixed;
    z-index: 1;
    right: 0;
    top: 20px;
    font-size: 0.8rem;
}

a:hover {
    cursor: pointer;
    .text-primary {
        color: $tertiary !important;
    }
    .text-graphite {
        color: $primary !important;
    }
    .text-silver {
        color: $white !important;
    }
    .text-light {
        color: $quaternary !important;
    }
    .text-deg1 {
        background-color: $tertiary;
        background-image: linear-gradient(45deg, $tertiary, $secondary);
    }
    .text-deg2 {
        background-color: $tertiary;
        background-image: linear-gradient(45deg, $tertiary, $primary);
    }
    .text-deg3 {
        background-color: $tertiary;
        background-image: linear-gradient(45deg, $tertiary, $quaternary);
    }
}


/* Acessibilidade */

.hc {
    background-color: #303030;
    -webkit-filter: invert(100%);
    -moz-filter: invert(100%);
    -ms-filter: invert(100%);
    -o-filter: invert(100%);
    filter: invert(100%);
}

#navbarAcessibilidade {
    .nav {
        .font-larger {
            margin-top: -4px;
            font-size: 1.4em;
        }
        .nav-item {
            a {
                padding: 2px 8px;
                color: $light;
                cursor: pointer;
            }
            a:hover {
                color: $secondary;
            }
            .nav-link {
                color: $white;
            }
        }
        .avatar {
            border-radius: 30px;
            width: 30px;
            height: 30px;
            border: 2px solid $tertiary;
        }
    }
}

#header {
    #menu-mini {
        ul {
            li {
                font-family: $font-family-heading;
                font-size: 1rem;
                font-style: italic;
                a {
                    color: $tertiary;
                }
                a.active {
                    color: $white;
                }
                a:hover {
                    color: $gray-400;
                }
            }
        }
    }
    #menu-principal {
        top: 90px;
        .nav-item-1 {
            position: unset;
            font-weight: 300;
            a.nav-link {
                color: $white;
                border-radius: 3px;
                &:hover {
                    background-color: $white;
                    color: $secondary;
                    display: inline-block;
                }
            }
            &.show {
                background-color: $quaternary;
                border-radius: 3px;
                a.nav-link {
                    color: $secondary;
                }
            }
            .dropdown-menu {
                transform: none !important;
                right: 0px;
                top: unset !important;
                margin-top: -2px;
                font-weight: 400;
            }
        }
    }
    #bar-search {
        input {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $white;
                opacity: 1;
                /* Firefox */
            }
            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: $white;
            }
            &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: $white;
            }
        }
        button {
            max-width: 40px;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
        }
    }
    svg.logo {
        height: 95px;
    }
}

@include media-breakpoint-down(xl) {
    #menu-principal {
        .nav-item-1 {
            .size-3 {
                font-size: 1.1rem !important;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    #nav-menu {
        position: inherit;
        #menu-principal {
            background-color: $tertiary;
            z-index: 10;
            position: absolute;
            width: 100%;
            left: 0px;
            margin-top: 75px;
            #menu-casa {
                border-bottom: 1px solid $quinary;
                padding-bottom: 10px;
                .level-1 {
                    font-size: 1.2rem;
                    border-bottom: none !important;
                    a {
                        padding: 8px 16px !important;
                        color: white !important;
                        font-weight: 100;
                    }
                    a:hover {
                        background-color: white;
                        color: $secondary !important;
                    }
                }
            }
            .nav-item-1 {
                .nav-link {
                    width: 100%;
                }
                .size-3 {
                    font-size: 1.2rem !important;
                }
            }
            .nav-link {
                border-radius: 0px !important;
            }
        }
    }
}

article.posts {
    img {
        max-width: 100%;
    }
}

$card-info-height: 250px;
$card-info-height-sm: 300px;
$card-info-height-md: 350px;
$card-info-height-lg: 450px;

.card-info {
    height: $card-info-height;
    position: relative;
    overflow: hidden;

    .card-title {
        position: absolute;
        background-image: linear-gradient(
            0deg,
            #{$secondary + "ee"} 20%,
            #{$secondary + "cc"} 50%,
            rgba(254, 255, 255, 0)
        );
        z-index: 2;
        bottom: 0px;
        left: 0px;
        width: 100%;
        padding: 70px 20px 20px 20px;
        margin-bottom: 0px;
        
        h4 {
            font-size: 0.8rem;
            text-align: center;
            word-break: break-word;
        }
    }
    .card-img {
        background-position-x: center;
        background-size: auto 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: $card-info-height;
        box-shadow: $shadow;
    }
}

@include media-breakpoint-up(sm) {
    .card-info {
        height: $card-info-height-sm;
        .card-title {
            h4 {
                font-size: 1rem;
                text-align: left;
            }
        }
        .card-img {
            height: $card-info-height-sm;
        }
    }
}

@include media-breakpoint-up(md) {
    .card-info {
        height: $card-info-height-md;
        .card-title {
            h4 {
                font-size: 1rem;
                text-align: left;
            }
        }
        .card-img {
            height: $card-info-height-md;
        }
    }
}

@include media-breakpoint-up(lg) {
    .card-info {
        height: $card-info-height-lg;
        .card-title {
            h4 {
                font-size: 1.4rem;
                text-align: left;
            }
        }
        .card-img {
            height: $card-info-height-lg;
        }
    }
}

@include media-breakpoint-up(xl) {
    .card-info {
        height: $card-info-height-lg;
        .card-title {
            h4 {
                font-size: 1.7rem;
                text-align: left;
            }
        }
        .card-img {
            height: $card-info-height-lg;
        }
    }
}

$card-news-height: 300px;
.card-news {
    height: $card-news-height;
    position: relative;
    overflow: hidden;
    .card-title {
        position: absolute;
        background-image: linear-gradient(0deg, #{$primary + "ee"} 20%, #{$primary + "cc"} 50%, rgba(254, 255, 255, 0));
        z-index: 2;
        bottom: 0px;
        left: 0px;
        width: 100%;
        padding: 70px 20px 20px 20px;
        margin-bottom: 0px;
    }
    .card-img {
        background-position-x: center;
        background-size: auto 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: $card-news-height;
        box-shadow: $shadow;
    }
}

.card-border {
    padding: 1px;
    background-image: linear-gradient(0deg, $primary, rgba(255, 255, 255, 0));
}

.card-news-others {
    .card-img.bg-blur {
        position: relative;
        overflow: hidden;
        background-color: $silver;
        height: 155px;
        div.bg-img,
        div.bg-img-blur {
            width: 100%;
            height: 100%;
            position: absolute;
            background-position: center center;
            background-repeat: no-repeat;
        }
        div.bg-img {
            z-index: 1;
            background-size: 100% auto;
        }
        div.bg-img-blur {
            background-size: auto 200%;
            filter: blur(3px);
        }
    }
    .size-5 {
        font-size: 1.2rem !important;
    }
}

@include media-breakpoint-up(md) {
    .card-news-others {
        .card-img.bg-blur {
            height: 145px;
        }
        .size-5 {
            font-size: 0.9rem !important;
        }
    }
}

@include media-breakpoint-up(lg) {
    .card-news-others {
        .card-img.bg-blur {
            height: 95px;
        }
        .size-5 {
            font-size: 0.8rem !important;
        }
    }
}

@include media-breakpoint-up(xl) {
    .card-news-others {
        .card-img.bg-blur {
            height: 112px;
        }
        .size-5 {
            font-size: 0.9rem !important;
        }
    }
}

@include media-breakpoint-up(xxl) {
    .card-news-others {
        .card-img.bg-blur {
            height: 125px;
        }
        .size-5 {
            font-size: 1.1rem !important;
        }
    }
}

#carouselPrincipal {
    box-shadow: 0px 0px 10px 1px #005caa;
    border-radius: 20px;
    .carousel-item {
        img {
            width: 130% !important;
            margin-left: -75px;
            border-radius: 20px;
        }
    }
    @include media-breakpoint-up(lg) {
        .carousel-item {
            text-align: center;
            img {
                width: 100% !important;
                margin-left: 0px;
            }
        }
    }
}

iframe.player_youtube {
    height: 320px;
}

@include media-breakpoint-up(lg) {
    iframe.player_youtube {
        height: 500px;
    }
}

.menu-footer {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 300px;
}

@include media-breakpoint-up(lg) {
    .menu-footer {
        overflow: visible;
        max-height: 1000px;
    }
}